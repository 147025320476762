export function formatPhone(number: number) {
  const numStr = number.toString();
  return (
    "(" +
    numStr.slice(0, 3) +
    ") " +
    numStr.slice(3, 6) +
    "-" +
    numStr.slice(6, numStr.length)
  );
}
