import React, { CSSProperties } from "react";
import { Standard } from "../../components/Buttons";
import { useDispatch } from "react-redux";
import { showConsultationModal } from "../../../actions";
import { useConsultationSelector } from "../../../utils/selectors";
import { consulationActive } from "../../../utils/consultation";
import { HomeSectionProps } from "./types";

const startStr = "Start your consultation!";
const continueStr = "Continue your consultation!";

const startBtnStyle: CSSProperties = {
  maxWidth: "500px",
  fontSize: "18px",
  alignItems: "center",
  marginTop: "40px",
  display: "flex",
  flexWrap: "wrap",
  padding: "9px 30px",
};

export default function Header({ homeRef }: HomeSectionProps) {
  const dispatch = useDispatch();
  const consultation = useConsultationSelector();

  function handleStartClick() {
    dispatch(showConsultationModal());
  }

  return (
    <div className="home-header" ref={homeRef}>
      <div className="home-header-left-block">
        <h2>zTech</h2>
        <p>Be innovative, be inspired, and grow your brand</p>
        <p>We build cutting-edge software to help you succeed</p>
        <Standard
          style={startBtnStyle}
          text={consulationActive(consultation) ? continueStr : startStr}
          onClick={handleStartClick}
        />
      </div>
      <div className="home-header-right-block">
        <img
          src="/assets/313-3137378_sonoda-umi-love-live-fan-art-png.png"
          alt="header-side-representation"
        />
      </div>
    </div>
  );
}
