export function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Correct username have 3 to 20 characters
 * @param username
 */

export function validateUsername(username: string): boolean {
  const re = /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  return re.test(String(username).toLowerCase());
}

/**
 * For now, correct password is any non-empty string. For future reference, you may use the following instead.
 * 
 * Minimum eight characters, at least one letter and one number:

  "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
  Minimum eight characters, at least one letter, one number and one special character:

  "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
  Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:

  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
  Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:

  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
  Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:

  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
 * 
 * @param password 
 */

export function validatePassword(password: string): boolean {
  return !!password;
}
