import React from "react";
import { HomeSectionProps } from "../types";

interface MemberType {
  img: string;
  name: string;
  bio: string;
}

const members: MemberType[] = [
  {
    name: "Rahmat Saeedi, CEO",
    img: "/assets/rahmat.jfif",
    bio: `Rahmat Saeedi is a full-time zTech contributor. After graduating from university,
    he has helped his clients setting up new businesses by setting up website, managed social
    media, and eventually went into full-stack development. Started as a hobby, he is now
    professionally helping clients starting their portfolio and businesses.`,
  },
  {
    name: "Viet Nhien Tran Duc, CTO",
    img: "/assets/viet.jfif",
    bio: `He is a professional software engineer who is currently working in corporate IT world.
    He spends his extra time learning new updates on recent changes in software world, such as
    new library. He is skilled in formulating the problems into simplest possible algorithm.
    At zTech, he ensures the quality of the software meets high industrial standards.`,
  },
  {
    name: "Jacky Chung, CFO",
    img: "/assets/37546763_10160463467125214_4282135750490718208_n.jpg",
    bio: `Jacky Chung is a successful financial consultant / manager with outstanding portfolio. 
    Throughout his career, he has  managed and grew his clients' large assets, often leading to
    high profits and early retirements. He is passionate about improving the accessibility to
    healthcare and helping parents' with disabled children.`,
  },
  {
    name: "Ashneil Dutt, COO",
    img: "/assets/ashtest.png",
    bio: `Ashneil has been in professional software industry for long time. With his knowledge of
    variety of knowledge, he has provided excellent service in delivering software to clients.`,
  },
  {
    name: "Carli Raul-Omar, Engineer",
    img: "/assets/1610944971588.jpg",
    bio: `After obtaining a degree in engineering physics, Carli has dived into a career as a
    software engineer, which has always been passionate about and excelled at. His well-equipped
    knowledge in the field, excitement to take on new challenge, humility and eagerness to learn
    have made an excellent team player in all of his proven past works. At zTech, he rigorously
    contributes to quality software.`,
  },
];

export default function Team({ homeRef }: HomeSectionProps) {
  return (
    <div className="home-team" ref={homeRef}>
      <h1 className="home-title">Team</h1>
      {members.map((member, i) => (
        <Member key={i} member={member} />
      ))}
    </div>
  );
}

function Member({ member }: { member: MemberType }) {
  return (
    <div className="home-team-member">
      <img src={member.img} alt={member.name} />
      <div className="home-team-member-details">
        <label>{member.name}</label>
        <span>{member.bio}</span>
      </div>
    </div>
  );
}
