import React, { useEffect, useRef } from "react";
import Header from "./Header";
import About from "./About";
import Highlights from "./Highlights";
import Plans from "./Plans";
import Inspirations from "./Inspirations";
import Team from "./Team";
import Contact from "./Contact";
import { usePathSelector } from "../../../utils/selectors";
import { Route } from "../../../enums";

export default function Home() {
  const headerRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const highlightsRef = useRef<HTMLDivElement>(null);
  const plansRef = useRef<HTMLDivElement>(null);
  const inspirationsRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const contactsRef = useRef<HTMLDivElement>(null);
  const path = usePathSelector();

  useEffect(() => {
    if (
      !headerRef.current ||
      !aboutRef.current ||
      !highlightsRef.current ||
      !teamRef.current ||
      !plansRef.current ||
      !inspirationsRef.current ||
      !contactsRef.current
    )
      return;

    switch (path) {
      case Route.Home:
        headerRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.About:
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.Highlights:
        highlightsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.Plans:
        plansRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.Inpirations:
        inspirationsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.Team:
        teamRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case Route.Contacts:
        contactsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  }, [
    path,
    headerRef,
    aboutRef,
    highlightsRef,
    plansRef,
    inspirationsRef,
    contactsRef,
  ]);

  return (
    <>
      <Header homeRef={headerRef} />
      <About homeRef={aboutRef} />
      <Highlights homeRef={highlightsRef} />
      <Inspirations homeRef={inspirationsRef} />
      <Plans homeRef={plansRef} />
      <Team homeRef={teamRef} />
      <Contact homeRef={contactsRef} />
    </>
  );
}
