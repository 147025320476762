export const SHOP_ADD_TO_CART = "SHOP_ADD_TO_CART";
export const SHOP_UPDATE_CART = "SHOP_UPDATE_CART";

interface IAddToCartAction {
  type: typeof SHOP_ADD_TO_CART;
  addition: CartState;
}

interface IUpdateCartAction {
  type: typeof SHOP_UPDATE_CART;
  update: CartState;
}

export type ShopActionTypes = IAddToCartAction | IUpdateCartAction;

export interface CartState {
  [productId: string]: { quantity: number };
}

export interface ShopState {
  cart: CartState;
}
