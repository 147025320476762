import React, { useRef, useEffect, useState } from "react";
import { useModalSelector } from "../../../utils/selectors";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../actions";
// import { LOGIN, CONSULTATION } from "../../../constants";
import { LOGIN } from "../../../constants";
import Login from "./Login";
// import Consultation from "./Consultation";
import { ModalState } from "../../../actions/types";
import classNames from "classnames";

const fadeInOutDuration = 0.3; // In seconds

export default function Modal() {
  const mode = useModalSelector();
  if (mode) return <TrueModal mode={mode} />;
  else return null;
}

function TrueModal({ mode }: { mode: ModalState | null }) {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isFadingOut, setIsFadingOut] = useState<boolean>(false);
  const [isFadingIn, setIsFadingIn] = useState<boolean>(true);

  const modalClass = classNames({
    "modal-fadeIn": !isFadingOut,
    "modal-fadeOut": isFadingOut,
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setIsFadingIn(false);
    }, fadeInOutDuration * 1000);
  }, []);

  useEffect(() => {
    if (!ref?.current) return;
    if (ref.current.getBoundingClientRect().top >= 0) return;
    ref.current.style.top = "0";
    ref.current.style.transform = "translate(-50%, 0px)";
  }, [modalRef]);

  function handleClick(e: any) {
    if (ref?.current && ref.current.contains(e.target)) return;
    hideTrueModal();
  }

  function hideTrueModal(): void {
    if (isFadingOut || isFadingIn) return;
    setIsFadingOut(true);
    setTimeout(() => dispatch(hideModal()), fadeInOutDuration * 1000);
  }

  return (
    <div
      id="modal"
      className={modalClass}
      ref={modalRef}
      style={{ animationDuration: `${fadeInOutDuration}s` }}
    >
      <div id="modal-content" ref={ref}>
        <button className="modal-close" onClick={hideTrueModal}>
          &times;
        </button>
        {mode === LOGIN && <Login />}
        {/* {mode === CONSULTATION && <Consultation onClose={hideTrueModal} />} */}
      </div>
    </div>
  );
}
