import { LOGIN, CONSULTATION } from "../../constants";

export const MODAL_OFF = "MODAL_OFF";

export const MODAL_LOGIN = "MODAL_LOGIN";

export const MODAL_CONSULTATION = "MODAL_CONSULTATION";

interface IHideModalAction {
  type: typeof MODAL_OFF;
}

interface ILoginModalAction {
  type: typeof MODAL_LOGIN;
}

interface IConsultationModalAction {
  type: typeof MODAL_CONSULTATION;
}

export type ModalActionTypes =
  | IHideModalAction
  | ILoginModalAction
  | IConsultationModalAction;

export type ModalState = typeof LOGIN | typeof CONSULTATION | null;
