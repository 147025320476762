import React from "react";
import { HomeSectionProps } from "../types";
import { formatPhone } from "../../../../utils/format";

enum City {
  Toronto = "Toronto",
  Edmonton = "Edmonton",
}

enum Province {
  Alberta = "Alberta",
  Ontario = "Ontario",
}

interface ContactType {
  label: string;
  name: string;
  address?: Address;
  phone: number;
  email: string;
}

interface Address {
  street: string;
  city: City;
  province: Province;
  postalCode: string;
}

const contacts: ContactType[] = [
  {
    label: "zTech Edmonton quarter",
    email: "info@ztech.ca",
    name: "Support team",
    phone: 5875578400,
  },
];

export default function Contact({ homeRef }: HomeSectionProps) {
  return (
    <div className="home-contact" ref={homeRef}>
      <h1 className="home-title">Contact Us</h1>
      <div className="home-contact-info">
        {contacts.map((contact, i) => {
          return (
            <div key={i} className="home-contact-quarter">
              <h4>{contact.label}</h4>
              {contact.address && (
                <div className="home-contact-quarter-address">
                  <label>{contact.address.street}</label>
                  <span>
                    <label>{contact.address.city}</label>
                    <label>{contact.address.province}</label>
                  </span>
                  <label>{contact.address.postalCode}</label>
                </div>
              )}
              <label>{contact.name}</label>
              <label>{contact.email}</label>
              <label>{formatPhone(contact.phone)}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
