import React, { Fragment } from "react";

export interface IDirectory {
  name: string;
  onClick?: (e?: React.MouseEvent | Element) => void;
  onHover?: (e?: React.MouseEvent | Element) => void;
}

interface IProps {
  directories: IDirectory[];
  addSeparators: boolean;
}

export default function Directories(props: IProps) {
  return (
    <div className="directories-container">
      {props.directories.map((directory: IDirectory, index: number) => {
        return (
          <Fragment key={`directory-item-${index}-${directory.name}`}>
            {!!index && (
              <div className="directory-bar">{props.addSeparators && "|"}</div>
            )}
            <div
              className="directory"
              onClick={directory.onClick}
              onMouseEnter={directory.onHover}
            >
              {directory.name}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
