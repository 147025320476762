import React from "react";
import Paragraph from "../../../components/Paragraph";
import { HomeSectionProps } from "../types";

const inspirations: InspirationType[] = [
  {
    title: "Build your portfolio",
    details: `Are you an aspiring artist, photographer, model, cause promoter, freelancer, etc?
    If so, portfolio can be crucial to your career as it is
    where you can reach larger audience`,
    img: "/assets/90881578_p0.jpg",
  },
  {
    title: "Promote your business",
    details: `You are running or helping a business and you want to
    next level. We got your back! We help you to promote homepage and legitimate emails
    for your business and set up a professional environment your clients can trust.`,
    img: "/assets/672582.png",
  },
  {
    title: "Platform with automated flow",
    details: `You have an entrepreneurial spirit and idea, but it requires automated process. You need an e-commerce platform where your customers can buy your products any time 24/7? You need an education platform where you can post assignments and students can submit them? You need a platform for interactive chat, booking appointments, placing and accepting orders, finding location, real-time mini-games, etc? (Just to name a few!). Engage your audience! We are here to simply the workflow for you!`,
    img: "/assets/E5LdNpZVcAErH2i.jfif",
  },
  {
    title: "Machine learning",
    details: `In this era of technology, it is indispensable to have well-structured library of algorithm to analyze and make right business decisions.`,
    img: "/assets/E5NS_Z-VIAQLgJC.jfif",
  },
];

export default function Inspirations({ homeRef }: HomeSectionProps) {
  return (
    <div className="home-inspirations" ref={homeRef}>
      <h1 className="home-title">Inspirations</h1>
      {inspirations.map((info, i) => (
        <Inspiration key={i} {...info} reverse={!!(i % 2)} />
      ))}
    </div>
  );
}

interface InspirationType {
  title: string;
  details: string;
  img: string;
}

interface InspirationProps extends InspirationType {
  reverse: boolean;
}

function Inspiration({ title, details, img, reverse }: InspirationProps) {
  function Info() {
    return (
      <div className="home-inspirations-section-left">
        <h2>{title}</h2>
        <Paragraph str={details} />
      </div>
    );
  }
  function Display() {
    return (
      <div className="home-inspirations-section-right">
        <div className="home-inspirations-img-wrapper">
          <img src={img} alt={img} />
        </div>
      </div>
    );
  }
  return (
    <div className="home-inspirations-section-wrapper">
      <div className="home-inspirations-section">
        {reverse ? (
          <>
            <Display />
            <Info />
          </>
        ) : (
          <>
            <Info />
            <Display />
          </>
        )}
      </div>
    </div>
  );
}
