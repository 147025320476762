export const CONSULTATION_TITLE = "CONSULTATION_TITLE";
export const CONSULTATION_NAME = "CONSULTATION_NAME";
export const CONSULTATION_EMAIL = "CONSULTATION_EMAIL";
export const CONSULTATION_PHONE = "CONSULTATION_PHONE";
export const CONSULTATION_DESCRIPTION = "CONSULTATION_DESCRIPTIONS";
export const CONSULTATION_LOCATION = "CONSULTATION_LOCATION";
export const CONSULTATION_RESET = "CONSULTATION_RESET";
export const CONSULATION_PLAN_SELECT = "CONSULATION_PLAN_SELECT";

export const CONSULTATION_PLAN_NOT_SELECTED = "CONSULTATION_PLAN_NOT_SELECTED";
export const CONSULTATION_PLAN_BASIC = "CONSULTATION_PLAN_BASIC";
export const CONSULTATION_PLAN_PRO = "CONSULTATION_PLAN_PRO";
export const CONSULTATION_PLAN_PREMIUM = "CONSULTATION_PLAN_PREMIUM";
export const CONSULTATION_PLAN_ENTERPRISE = "CONSULTATION_PLAN_ENTERPRISE";

interface IUpdateConsultationTitle {
  type: typeof CONSULTATION_TITLE;
  title: string;
}

interface IUpdateConsultationName {
  type: typeof CONSULTATION_NAME;
  name: string;
}

interface IUpdateConsultationEmail {
  type: typeof CONSULTATION_EMAIL;
  email: string;
}

interface IUpdateConsultationPhone {
  type: typeof CONSULTATION_PHONE;
  phone: number;
}

interface IUpdateConsultationDescription {
  type: typeof CONSULTATION_DESCRIPTION;
  description: string;
}

interface IUpdateConsultationLocation {
  type: typeof CONSULTATION_LOCATION;
  location: string;
}

interface IResetConsultation {
  type: typeof CONSULTATION_RESET;
}

interface ISelectConsultationPlan {
  type: typeof CONSULATION_PLAN_SELECT;
  plan: ConsultationPlanTypes;
}

export type ConsultationTypes =
  | IUpdateConsultationTitle
  | IUpdateConsultationName
  | IUpdateConsultationEmail
  | IUpdateConsultationPhone
  | IUpdateConsultationDescription
  | IUpdateConsultationLocation
  | IResetConsultation
  | ISelectConsultationPlan;

export type ConsultationPlanTypes =
  | typeof CONSULTATION_PLAN_BASIC
  | typeof CONSULTATION_PLAN_NOT_SELECTED
  | typeof CONSULTATION_PLAN_PRO
  | typeof CONSULTATION_PLAN_PREMIUM
  | typeof CONSULTATION_PLAN_ENTERPRISE;

export interface ConsultationState {
  title: string;
  name: string;
  email: string;
  phone: number;
  description: string;
  plan: ConsultationPlanTypes;
  location: string;
}
