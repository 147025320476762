import React from "react";
import PriceCard, { IPricingCardData, PER_MONTH } from "./PriceCard";
import className from "classnames";
import { useDispatch } from "react-redux";
import { showConsultationModal, selectPlan } from "../../../../actions";
import {
  ConsultationPlanTypes,
  CONSULTATION_PLAN_NOT_SELECTED,
  CONSULTATION_PLAN_BASIC,
  CONSULTATION_PLAN_PRO,
  CONSULTATION_PLAN_PREMIUM,
  CONSULTATION_PLAN_ENTERPRISE,
} from "../../../../actions/types";
import { useConsultationPlanSelector } from "../../../../utils/selectors";
import { HomeSectionProps } from "../types";

const pricingCardList: IPricingCardData[] = [
  {
    name: "Basic",
    headline: "Static website",
    price: { type: PER_MONTH, price: 100 },
    summary: `You want to grow your brand and need a website for your audience. You want to tell your audience what you
    are about, what you are doing, and how to get in contact with you, etc. Here is the choice for you!`,
    features: ["Website"],
    dispatch: CONSULTATION_PLAN_BASIC,
  },
  {
    name: "Pro",
    headline: "Cloud server",
    price: { type: PER_MONTH, price: 300 },
    summary: `Engage your audience and implement automations your business with cloud database and back-end structures.
    Do you want to start an online ecommerce store to sell your merchandise, allow users to have an account for more friendly interface,
    or have a feed bulletin to keep your audience updated with your own admin panel, etc? This is the plan for you!`,
    features: [
      "Server-powered Website",
      "Database engineering",
      "Cloud hosted server",
      "Automation",
      "Custom user-engaging platform",
    ],
    dispatch: CONSULTATION_PLAN_PRO,
  },
  {
    name: "Advanced",
    headline: "Advanced application",
    price: { type: PER_MONTH, price: 500 },
    summary: `If you have a pool of tens of thousands of audience, or you want to develop advanced cloud-based real-time games,
    or want to immerse your clients with VR, AR technologies, etc? We got your back!`,
    features: [
      "Server-powered Website",
      "Database engineering",
      "Cloud hosted server",
      "Automation",
      "Games",
      "Rendering engineering",
      "Advanced custom user-engaging platform",
    ],
    dispatch: CONSULTATION_PLAN_PREMIUM,
  },
  {
    name: "Enterprise",
    headline: "Get a full-time software engineer",
    price: { type: PER_MONTH, price: 5000 },
    summary: `You are very ambitious, and you want a software engineer to dedicate 40hr or more per week for your business. We will
    match you with a qualified candidate for your needs!`,
    features: ["Anything you ever wanted"],
    dispatch: CONSULTATION_PLAN_ENTERPRISE,
  },
];

export default function Plan({ homeRef }: HomeSectionProps) {
  const plan = useConsultationPlanSelector();
  const dispatch = useDispatch();
  const availablePlan = getAvailablePlan(plan, pricingCardList);
  const selectPlanClassNames = className([
    "home-plans-pricing-card-select-plan",
    {
      "home-plans-pricing-card-select-plan-active":
        availablePlan !== CONSULTATION_PLAN_NOT_SELECTED,
      "home-plans-pricing-card-select-plan-inactive":
        availablePlan === CONSULTATION_PLAN_NOT_SELECTED,
    },
  ]);

  function getAvailablePlan(
    plan: ConsultationPlanTypes,
    pricingCardList: IPricingCardData[]
  ): ConsultationPlanTypes {
    return plan === CONSULTATION_PLAN_NOT_SELECTED
      ? CONSULTATION_PLAN_NOT_SELECTED
      : pricingCardList.find(
          (pricingCardData: IPricingCardData) =>
            pricingCardData.dispatch === plan
        )?.dispatch || CONSULTATION_PLAN_NOT_SELECTED;
  }

  function getPlanSelectionText(pricingCardList: IPricingCardData[]) {
    return availablePlan === CONSULTATION_PLAN_NOT_SELECTED
      ? "SELECT A PLAN"
      : `GET YOUR ${pricingCardList
          .find(
            (pricingCardData: IPricingCardData) =>
              availablePlan === pricingCardData.dispatch
          )
          ?.name.toUpperCase()} SUBSCRIPTION`;
  }

  function getPricingCards(pricingCardList: IPricingCardData[]) {
    return pricingCardList.map(
      (pricingCardData: IPricingCardData, index: number) => (
        <PriceCard
          key={`home-pricing-card-${index}-${pricingCardData.dispatch}`}
          onSelect={() => dispatch(selectPlan(pricingCardData.dispatch))}
          selected={plan === pricingCardData.dispatch}
          {...pricingCardData}
        />
      )
    );
  }

  function handleGetSubscription() {
    if (availablePlan !== CONSULTATION_PLAN_NOT_SELECTED)
      dispatch(showConsultationModal());
  }

  return (
    <div className="home-plans-container" ref={homeRef}>
      <h1>Plans</h1>
      <h2>Select the plan you need, starting from competitive $100 / month</h2>
      <div className="home-plan-short-intro">
        <p>
          At zTech, we prioritize in trustful relationship with our clients. We
          are upfront on the pricing and deliver the products that live up to
          our reputations. The clients will never pay any fees that we have not
          agreed on, and we actively communicate to update the site.
        </p>
        <p>
          Consultation with us is absolutely free. Once we have agreed on the
          terms and start your subscription, we start with buying available
          domain name, which varies greatly in price, but we charge no
          commission for it. Then, your application will be made ready within
          the time frame we have agreed on (usually within 2 weeks, but it
          depends on the complexity of the requests as well). As long as you are
          subscribed, we constantly update and maintain the applications to suit
          your needs at any time. Each plan requires minimum of 3 months
          subscriptions.
        </p>
      </div>
      <div className="home-plans-pricing-card-select-plan-container">
        <div className={selectPlanClassNames} onClick={handleGetSubscription}>
          {getPlanSelectionText(pricingCardList)}
        </div>
      </div>
      <div className="home-plans-pricing-card-container">
        {getPricingCards(pricingCardList)}
      </div>
    </div>
  );
}
