import {
  CAD,
  currencyTypes,
  CurrencyUpdate,
  CHANGE_CURRENCY,
} from "../actions/types";

const initialState = CAD;

const currencyReducer = (
  state: currencyTypes = initialState,
  action: CurrencyUpdate
): currencyTypes => {
  switch (action.type) {
    case CHANGE_CURRENCY:
      return action.currency;
    default:
      return state;
  }
};

export default currencyReducer;
