import React from "react";
import HighlightPanel, { IHighlightPanelData } from "./HighlightPanel";
import {
  ClipboardData,
  Gear,
  Stopwatch,
  Laptop,
  Tools,
  Terminal,
} from "../../../../utils/bootstrap";
import { HomeSectionProps } from '../types'

const highlights: IHighlightPanelData[] = [
  {
    title: "Web development",
    description: `It is crucial that your entrepreneurial ideas are presented to the market in a user-friendly, concise, and clear manner. 
    We build the website with relevant domain name to engage larger audience.`,
    svg: Laptop,
  },
  {
    title: "Data management",
    description: `There is a huge scientific discipline in business data. Maintaining and understanding your business data is critical in decision making. 
    We maintain the database in the most sensible manner for better user experience and help with business growth.`,
    svg: ClipboardData,
  },
  {
    title: "Cutting edge technologies",
    description: `We are special in that we never use any outdated technologies (unless requested otherwise). 
    We make sure our team is up-to-date with the latest technologies for the best software possible. In fact, our name, zTech, is derived from this idea!`,
    svg: Terminal,
  },
  {
    title: "Automation",
    description: `Industries cut the costs and increase profit of margin with automation, and so should you. We automate any repetitive works, 
    and even build decision making algorithms where possible so you can manage your business easier and endeavor further expansion.`,
    svg: Gear,
  },
  {
    title: "Trustworthy",
    description: `We prioritize in trustful relationship with our clients. We are committed to delivering our products on-time and stay within the budget we have agreed on.
    We actively communicate with our clients for any changes on the applications.`,
    svg: Stopwatch,
  },
  {
    title: "Maintenance & Continuous update",
    description: `As long as you are subscribed to our monthly service, you may request further changes to the software at any time.`,
    svg: Tools,
  },
];

export default function Highlights({ homeRef }: HomeSectionProps) {
  function getHighlightPanels(highlights: IHighlightPanelData[]) {
    return highlights.map((highlight: IHighlightPanelData, index: number) => (
      <HighlightPanel key={`home-highlight-panel-${index}`} {...highlight} />
    ));
  }
  return (
    <div className="home-highlights" ref={homeRef}>
      <h1>Highlights</h1>
      <div className="home-highlight-panels-container">
        {getHighlightPanels(highlights)}
      </div>
    </div>
  );
}
