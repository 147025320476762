import React from "react";

export interface IHighlightPanelData {
  title: string;
  description: string;
  svg: () => JSX.Element;
}

export default function HighlightPanel(props: IHighlightPanelData) {
  return (
    <div className="home-highlight-panel">
      <div className="home-highlight-panel-header">
        <div className="home-highlight-panel-thumbnail">{props.svg()}</div>
        <h3 className="home-highlight-panel-highlight">{props.title}</h3>
      </div>
      <div className="home-highlight-panel-detail">
        <p>{props.description}</p>
      </div>
    </div>
  );
}
