import React from "react";
// import { useHistory } from "react-router-dom";
// import Directories, { IDirectory } from "../Directories";
// import { DirectoryPath } from "../../../enums/DirectoryPath";

export default function Footer() {
  // const history = useHistory();
  const date = new Date();
  // const footerDirectories: IDirectory[] = [
  //   { name: "Contact Us" },
  //   { name: "Q & A" },
  //   { name: "Site Map" },
  //   { name: "Terms and Conditions" },
  //   { name: "Career", onClick: () => history.push(DirectoryPath.Careers) },
  // ];

  // Uncomment below after other pages have completed

  return (
    <div className="footer">
      {/* <Directories directories={footerDirectories} addSeparators={true} /> */}
      <div>
        Copyright &copy; 2019-{date.getFullYear()} zTech, LLC. All rights
        reserved.
      </div>
    </div>
  );
}
