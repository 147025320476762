import React, { CSSProperties, FormEvent } from "react";

interface Props {
  value: string;
  style?: CSSProperties;
  className?: string;
  onSubmit?: (
    event: FormEvent<HTMLFormElement> | FormEvent<HTMLInputElement>
  ) => void;
  disabled?: boolean;
}

export function InputSubmit({
  value,
  style,
  className,
  onSubmit,
  disabled,
}: Props) {
  return (
    <input
      className={`button-input-submit${className ? ` ${className}` : ""}`}
      type="submit"
      onSubmit={onSubmit}
      style={style}
      disabled={disabled}
      value={value}
    />
  );
}
