import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./view/components/NavBar";
import Footer from "./view/components/Footer";
import Home from "./view/pages/Home";
import PageNotFound from "./view/pages/PageNotFound";
// import HowItWorks from "./view/pages/HowItWorks";
import Modal from "./view/components/Modal";
// import Shop from "./view/pages/Shop";
// import CheckoutPage from "./view/pages/Checkout";
// import Careers from "./view/pages/Careers";
// import { ScrollToTop } from "./utils/route";
import "./sass/index.scss";

function App() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <div className="App">
        <NavBar />
        <Modal />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
            {/* <Route path="/howitworks" exact component={HowItWorks} />
          <Route path="/shop" exact component={Shop} />
          <Route path="/checkout" exact component={CheckoutPage} />
          <Route path="/careers" exact component={Careers} /> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
