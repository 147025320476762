import { useSelector } from "react-redux";
import { IRootState } from "../reducers";

export function useModalSelector() {
  return useSelector((state: IRootState) => state.modal);
}

export function useConsultationSelector() {
  return useSelector((state: IRootState) => state.consultation);
}

export function useCurrencySelector() {
  return useSelector((state: IRootState) => state.currency);
}

export function useCartSelector() {
  return useSelector((state: IRootState) => state.shop.cart);
}

export function useConsultationPlanSelector() {
  return useSelector((state: IRootState) => state.consultation.plan);
}

export function usePathSelector() {
  return useSelector((state: IRootState) => state.route.path);
}
