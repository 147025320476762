import {
  MODAL_OFF,
  MODAL_LOGIN,
  ModalActionTypes,
  MODAL_CONSULTATION,
} from "./types";

export function hideModal(): ModalActionTypes {
  return { type: MODAL_OFF };
}

export function showLoginModal(): ModalActionTypes {
  return { type: MODAL_LOGIN };
}

export function showConsultationModal(): ModalActionTypes {
  return { type: MODAL_CONSULTATION };
}
