import { combineReducers } from "redux";
import modalReducer from "./modalReducer";
import consultationReducer from "./consultationReducer";
import currencyReducer from "./currencyReducer";
import shopReducer from "./shopReducer";
import routeReducer, { setPath } from './routeReducer';
import {
  ModalState,
  ConsultationState,
  currencyTypes,
  ShopState,
  RouteState
} from "../actions/types";

export { setPath }

export default combineReducers({
  modal: modalReducer,
  consultation: consultationReducer,
  currency: currencyReducer,
  shop: shopReducer,
  route: routeReducer
});

export interface IRootState {
  modal: ModalState;
  consultation: ConsultationState;
  currency: currencyTypes;
  shop: ShopState;
  route: RouteState;
}
