import React, { CSSProperties } from "react";

const BUTTON = "button";

interface Props {
  text: string;
  style?: CSSProperties;
  onClick?: () => void;
  type?: typeof BUTTON;
  classNames?: string;
}

export function Standard({ text, style, onClick, type, classNames }: Props) {
  return (
    <button
      className={`button-standard${classNames ? ` ${classNames}` : ""}`}
      style={style}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
}
