// import { takeLatest, all, select } from "redux-saga/effects";
// import { setPath, IRootState } from "../reducers";
// import { Route } from "../enums";

export function* routeSaga() {
  //   yield all([takeLatest(setPath.type, setPathSaga)]);
}

// function* setPathSaga() {
//   const path: Route = yield select((state: IRootState) => state.route.path);
//   // Add resolve logic here
// }
