import {
  MODAL_OFF,
  MODAL_LOGIN,
  MODAL_CONSULTATION,
  ModalActionTypes,
  ModalState,
} from "../actions/types";
import { LOGIN, CONSULTATION } from "../constants";

const initialState = null;

const modalReducer = (
  state = initialState,
  action: ModalActionTypes
): ModalState => {
  switch (action.type) {
    case MODAL_OFF:
      return null;
    case MODAL_LOGIN:
      return LOGIN;
    case MODAL_CONSULTATION:
      return CONSULTATION;
    default:
      return state;
  }
};

export default modalReducer;
