import {
  ConsultationState,
  CONSULTATION_PLAN_NOT_SELECTED,
} from "../../actions/types";

export function consulationActive(consultation: ConsultationState): boolean {
  return (
    !!consultation.description ||
    !!consultation.email ||
    !!consultation.name ||
    !isNaN(consultation.phone) ||
    consultation.plan !== CONSULTATION_PLAN_NOT_SELECTED ||
    !!consultation.title
  );
}
