import React from "react";
import { HomeSectionProps } from "./types";

export default function About({ homeRef }: HomeSectionProps) {
  return (
    <div className="home-about" ref={homeRef}>
      <h1 className="home-title">Our mission</h1>
      <p>
        ...In this era of technology, we help our clients to connect with their
        noisy markets by providing the tech solutions that match the clients'
        needs. Whether you simply wanted to host a website to promote your
        brand, build the platforms to engage your clients and staffs more for
        your business, or even build a game, we are here to bring your
        entrepreneurial ideas to reality.
      </p>
      <p>
        We are a team of software engineers and marketing strategists from professional
        industries in Toronto and Edmonton. We prioritize in building our relationship
        with our clients by delivering our software that match client’s needs on time
        and within the budget...
      </p>
    </div>
  );
}
