import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteState } from '../actions/types';
import { Route } from '../enums'

const initialState: RouteState = {
  path: null
}

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setPath(state, action: PayloadAction<Route>) {
      state.path = action.payload
    }
  }
})

export const { setPath } = routeSlice.actions

export default routeSlice.reducer