/**
 * Currencies must be supported by PayPal and they must have the same code as exchangeratesapi.io for api.
 * The full list are shown here:
 *
 * https://developer.paypal.com/docs/api/reference/currency-codes/
 * https://exchangeratesapi.io/
 *
 * Note: Upon adding or removing a currency, be sure to also update the file(s) in '../../utils/currency'
 */

export const USD = "USD"; // United States dollar
export const CAD = "CAD"; // Canadian dollar
export const JPY = "JPY"; // Japanese yen
export const EUR = "EUR"; // Euro
export const RUB = "RUB"; // Russian ruble
export const INR = "INR"; // Indian rupee

export type currencyTypes =
  | typeof USD
  | typeof JPY
  | typeof EUR
  | typeof RUB
  | typeof INR
  | typeof CAD;

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

export interface CurrencyUpdate {
  type: typeof CHANGE_CURRENCY;
  currency: currencyTypes;
}
