import React from "react";
import Directories, { IDirectory } from "../Directories";
import { useDispatch } from "react-redux";
import * as actions from "../../../actions";
// import { useHistory } from "react-router-dom";
// import { DirectoryPath } from "../../../enums";
import { Route } from "../../../enums";
import { setPath } from "../../../reducers"

interface NavBarTitleProps {
  onClick: () => void;
}

export default function NavBar() {
  const dispatch = useDispatch();
  // const history = useHistory();

  const navBarDirectories: IDirectory[] = [
    // { name: "Home", onClick: goHome },
    { name: "Home", onClick: () => dispatch(setPath(Route.Home)) },
    { name: "About", onClick: () => dispatch(setPath(Route.About)) },
    { name: "Plans", onClick: () => dispatch(setPath(Route.Plans)) },
    { name: "Inspirations", onClick: () => dispatch(setPath(Route.Inpirations)) },
    { name: "Team", onClick: () => dispatch(setPath(Route.Team)) },
    { name: "Contacts", onClick: () => dispatch(setPath(Route.Contacts)) },
    // { name: "How it works" },
    // { name: "Team" },
    // { name: "Get quote" },
  ];

  const loginDirectory: IDirectory[] = [
    { name: "Login", onClick: () => dispatch(actions.showLoginModal()) },
  ];

  function goHome() {
    // Uncomment this when more pages are available
    // history.push(DirectoryPath.Home);
  }

  return (
    <div className="navbar">
      <NavBarTitle onClick={goHome} />
      <Directories directories={navBarDirectories} addSeparators={false} />
      <Directories directories={loginDirectory} addSeparators={false} />
    </div>
  );
}

function NavBarTitle(props: NavBarTitleProps) {
  return (
    <div className="navbar-title" onClick={props.onClick}>
      <div className="navbar-title-top">
        <img
          src="/assets/logoHeader.png"
          alt="Logo"
        />
        <h1>zTech</h1>
      </div>
      <p className="navbar-title-bottom">
        <span>Be Innovative, </span>
        <span>Be Inspired...</span>
      </p>
    </div>
  );
}
