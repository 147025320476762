import {
  CONSULTATION_TITLE,
  CONSULTATION_EMAIL,
  CONSULTATION_PHONE,
  CONSULTATION_LOCATION,
  CONSULTATION_DESCRIPTION,
  ConsultationTypes,
  CONSULTATION_NAME,
  CONSULTATION_RESET,
  CONSULATION_PLAN_SELECT,
  ConsultationPlanTypes,
} from "./types";

export function updateConsultationTitle(title: string): ConsultationTypes {
  return { type: CONSULTATION_TITLE, title };
}

export function updateConsultationName(name: string): ConsultationTypes {
  return { type: CONSULTATION_NAME, name };
}

export function updateConsultationEmail(email: string): ConsultationTypes {
  return { type: CONSULTATION_EMAIL, email };
}

export function updateConsultationPhone(phone: number): ConsultationTypes {
  return { type: CONSULTATION_PHONE, phone };
}

export function updateConsultationLocation(
  location: string
): ConsultationTypes {
  return { type: CONSULTATION_LOCATION, location };
}

export function updateConsultationDescription(
  description: string
): ConsultationTypes {
  return { type: CONSULTATION_DESCRIPTION, description };
}

export function resetConsultation(): ConsultationTypes {
  return { type: CONSULTATION_RESET };
}

export function selectPlan(plan: ConsultationPlanTypes): ConsultationTypes {
  return { type: CONSULATION_PLAN_SELECT, plan };
}
