import {
  SHOP_ADD_TO_CART,
  SHOP_UPDATE_CART,
  ShopState,
  ShopActionTypes,
  CartState,
} from "../actions/types";

const initialState: ShopState = { cart: {} };

const shopReducer = (
  state = initialState,
  action: ShopActionTypes
): ShopState => {
  switch (action.type) {
    case SHOP_ADD_TO_CART:
      return { ...state, cart: addToCart(action.addition, state.cart) };
    case SHOP_UPDATE_CART:
      return { ...state, cart: action.update };
    default:
      return state;
  }
};

export default shopReducer;

function addToCart(addition: CartState, previousCart: CartState): CartState {
  return Object.keys(addition).reduce((acc: CartState, id: string) => {
    return {
      ...acc,
      [id]: {
        quantity: Math.max(addition[id].quantity + (acc[id]?.quantity || 0), 0),
      },
    };
  }, previousCart);
}
