import React from "react";
import className from "classnames";
import { ConsultationPlanTypes } from "../../../../actions/types";

export const PER_MONTH = "PER_MONTH";

export interface IPricingCardData {
  name: string;
  headline: string;
  price: {
    type: typeof PER_MONTH;
    price: number;
  };
  summary: string;
  features: string[];
  dispatch: ConsultationPlanTypes;
}

interface Props extends IPricingCardData {
  onSelect: () => void;
  selected: boolean;
}

export default function PriceCard(props: Props) {
  const selectButtonClassNames = className(["home-plans-price-card-select"], {
    "home-plans-price-card-select-active": !props.selected,
    "home-plans-price-card-select-inactive": props.selected,
  });

  const cardThemClassNames = className([
    "home-plans-price-card-container-top",
    {
      "home-plans-price-card-container-top-active": props.selected,
      "home-plans-price-card-container-top-inactive": !props.selected,
    },
  ]);

  function getFeatures(features: string[]) {
    return (
      <div className="home-plan-price-card-features">
        {features.map((feature: string, index: number) => (
          <div
            className="home-plan-price-card-feature"
            key={`home-plan-card-${index}`}
          >
            {feature}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="home-plans-price-card-container">
      <div className={cardThemClassNames}>
        <h3 className="home-plans-price-card-name">{props.name}</h3>
        <div className="home-plans-price-card-headline-pricing">
          <h4>{props.headline}</h4>
          {props.price.type === PER_MONTH && (
            <h5>$ {props.price.price} / month</h5>
          )}
        </div>
        <div
          className={selectButtonClassNames}
          onClick={() => props.onSelect()}
        />
      </div>
      <div className="home-plans-price-card-container-bottom">
        <p>{props.summary}</p>
        {getFeatures(props.features)}
      </div>
    </div>
  );
}
