import React, { FormEvent, useState, ChangeEvent } from "react";
import classNames from "classnames";
import { validateUsername, validatePassword } from "../../../utils/regex";
import { InputSubmit } from "../Buttons";

// This is faking the api call at the moment. Please fix later.

const loggingTimeout = 1000;

const ERROR = "ERROR";
const AWAITING = "AWAITING";

const noDatabaseMsg: string =
  "We were unable to find matching username in our database";
const invalidInputMsg: string = "Please insert valid username and password";
const loggingInMsg: string = "Logging in...";

interface ILoginModalGuide {
  type: typeof ERROR | typeof AWAITING | null;
  message: string;
}

interface ILoginmodalInputs {
  username: string;
  password: string;
}

export default function Login() {
  const [message, setMessage] = useState<ILoginModalGuide>({
    type: null,
    message: "",
  });
  const [inputs, setInputs] = useState<ILoginmodalInputs>({
    username: "",
    password: "",
  });
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const messageClassNames = classNames({
    "modal-login-error": message.type === ERROR,
    "modal-login-awaiting": message.type === AWAITING,
  });

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  function handleSubmit(
    event: FormEvent<HTMLFormElement> | FormEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    if (timer) return;
    if (
      !validateUsername(inputs.username) ||
      !validatePassword(inputs.password)
    ) {
      setMessage({ type: ERROR, message: invalidInputMsg });
    } else {
      setMessage({ type: AWAITING, message: loggingInMsg });
      setTimer(
        setTimeout(() => {
          setMessage({ type: ERROR, message: noDatabaseMsg });
          setTimer(null);
        }, loggingTimeout)
      );
    }
  }

  return (
    <div className="modal-login-container">
      <h2>Log in</h2>
      {message.type && <p className={messageClassNames}>{message.message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="modal-form-name">Username</div>
        <input
          name="username"
          className="modal-login-input"
          type="text"
          placeholder="Enter your username"
          value={inputs.username}
          disabled={!!timer}
          onChange={handleInputChange}
        />
        <div className="modal-form-name">Password</div>
        <input
          name="password"
          className="modal-login-input"
          type="password"
          placeholder="Enter your password"
          value={inputs.password}
          disabled={!!timer}
          onChange={handleInputChange}
        />
        <InputSubmit
          value={"Login"}
          disabled={!!timer}
          onSubmit={handleSubmit}
        />
      </form>
      <p className="modal-login-note">
        * Admin panel is only available to our current clients. Get your
        consultation today and strategize the growth of your brand!
      </p>
    </div>
  );
}
