import {
  ConsultationState,
  ConsultationTypes,
  CONSULTATION_TITLE,
  CONSULTATION_NAME,
  CONSULTATION_EMAIL,
  CONSULTATION_PHONE,
  CONSULTATION_LOCATION,
  CONSULTATION_DESCRIPTION,
  CONSULATION_PLAN_SELECT,
  CONSULTATION_RESET,
  CONSULTATION_PLAN_NOT_SELECTED,
} from "../actions/types";

const initialState: ConsultationState = {
  title: "",
  name: "",
  email: "",
  phone: NaN,
  description: "",
  plan: CONSULTATION_PLAN_NOT_SELECTED,
  location: "",
};

const consultationReducer = (
  state = initialState,
  action: ConsultationTypes
): ConsultationState => {
  switch (action.type) {
    case CONSULTATION_TITLE:
      return { ...state, title: action.title };
    case CONSULTATION_NAME:
      return { ...state, name: action.name };
    case CONSULTATION_EMAIL:
      return { ...state, email: action.email };
    case CONSULTATION_PHONE:
      return { ...state, phone: action.phone };
    case CONSULTATION_LOCATION:
      return { ...state, location: action.location };
    case CONSULTATION_DESCRIPTION:
      return { ...state, description: action.description };
    case CONSULATION_PLAN_SELECT:
      return { ...state, plan: action.plan };
    case CONSULTATION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default consultationReducer;
